<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { receptionCity } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/honorAndQualification/getList?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          controls: []
        },
        columns: [
          {
            key: 'qualificationName',
            title: '荣誉资质名',
            width: '100px',
          },
          {
            key: 'sort',
            title: '排序',
            width: '100px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }

        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: ['createdDate'],
          /* 默认排序 */
          defaultSort: '-createdDate'
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建荣誉资质',
            type: 'form',
            permission: '/clientUser/honorAndQualification/insert',
            config: {
              color: 'primary',
              title: '新建荣誉资质',
              submitUrl: "/clientUser/honorAndQualification/insert",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'qualificationName',
                  label: '资质名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {},
                {
                  key: 'picture',
                  label: '图片',
                  required: true,
                  tips: "建议上传大小不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/honorAndQualification/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条荣誉资质，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/honorAndQualification/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/honorAndQualification/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/honorAndQualification/get",
              submitUrl: "/clientUser/honorAndQualification/update",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'qualificationName',
                  label: '资质名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {},
                {
                  key: 'picture',
                  label: '图片',
                  required: true,
                  tips: "建议上传大小不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000
                  }
                },
                {}
              ]
            }
          },
        ]
      }
    }
  },
  async mounted() {
    
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>